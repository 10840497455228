import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['flash']

  connect() { }

  closeFlash() {
    this.flashTarget.remove()
  }
}

import { Controller } from "stimulus"
import consumer from '../../channels/consumer'

export default class extends Controller {
  initialize() {
    console.log('Initializing controller')
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create("SettingChannel", {
        connected() {
          //console.log('You are ready for settingChannel ')
        },
        disconnected() {
          //console.log('You are no longer ready for registration')
        },
        received(data) {
        }
      })
    }
    return this._subscription
  }
  toggleLogin() {
    this.subscription().perform('toggle_login')
  }
  toggleCheckin() {
    this.subscription().perform('toggle_checkin')
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['input', 'alert']
  connect() {
      this.countCharacters()
  }
  countCharacters(){
        let regex = /[^A-Za-z0-9]+/g
        let string = this.inputTarget.value
        let length = string.length
      if(length > 11 || string.match(regex)){
          this.alertTarget.innerHTML = this.inputTarget.dataset.alert
      } else {
          this.alertTarget.innerHTML = ''
      }
  }
}

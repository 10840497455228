import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
import { Sortable } from '@shopify/draggable'
import { Plugins } from '@shopify/draggable';
import SwapAnimation from '@shopify/draggable/lib/plugins/swap-animation';

export default class extends Controller {
	static targets = ['list', 'speaker']
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create("SpeakersChannel", {
				connected() {},
				disconnected(){},
				received(data){
					if (data.cableReady) CableReady.perform(data.operations)
				}
			})
		}
		return this._subscription
	}

	connect() {
		this.sortSpeakers()
	  }

	sortSpeakers(){
		let _this = this
		if (this.hasSpeakerTarget) {
		  this.speakerTargets.forEach(speaker => {
			speaker.setAttribute('style', 'z-index: 1000;')
		  })
		  const sortable = new Sortable(this.listTargets, {
			draggable: 'tr',
			swapAnimation: {
			  duration: 400,
			  easingFunction: 'ease-in-out',
			  vertical: true
			},
			plugins: [Plugins.SwapAnimation]
		  })
		  sortable.on('sortable:stop', function(event) {
			let array = Array.from(event.newContainer.children)
			array.forEach((speaker, index) => {
			  if (speaker.classList.contains('draggable--original')) {
				array.splice(index, 1)
			  } else if (speaker.classList.contains('draggable-mirror')) {
				array.splice(index, 1)
			  }
			})
			let positions = array.map(speaker => speaker.dataset.id)
			let data = { positions: positions }
			_this.subscription().perform('update_speaker_positions', {data})
		  })
		}
	}

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fileInput", "preview"]

  connect() { }

  preview(e) {
    let preview = this.previewTarget
    let file = this.fileInputTarget.files[0]
    let reader = new FileReader();
    reader.addEventListener("load", function () {
      // convert image file to base64 string
      preview.src = reader.result;
      preview.classList.remove('hidden')
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }


  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    var checks = document.querySelectorAll(".check");
    var max = 3;
    for (var i = 0; i < checks.length; i++)
      checks[i].onclick = selectiveCheck;
    function selectiveCheck(event) {
      var checkedChecks = document.querySelectorAll(".check:checked");
      if (checkedChecks.length >= max + 1)
        return false;
    }
  }
}

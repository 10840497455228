import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['time', 'days', 'hours', 'minutes', 'seconds']

  connect() {
    let countDownDate = new Date(this.timeTarget.dataset.time).getTime();
    let _this = this
    // Update the count down every 1 second
    let x = setInterval(function () {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);


      if(days < 10){
          days = '0' + days
      }
      if(hours < 10){
          hours = '0' + hours
      }
      if(minutes < 10){
          minutes = '0' + minutes
      }
      if(seconds < 10){
          seconds = '0' + seconds
      }

      // Display the result in the element with id="demo"

      _this.daysTarget.innerHTML = days;
      _this.hoursTarget.innerHTML = hours;
      _this.minutesTarget.innerHTML = minutes;
      _this.secondsTarget.innerHTML = seconds;

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  }
}

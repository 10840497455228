import { Controller } from "stimulus"
import { render, register } from 'timeago.js';
import nb_NO from 'timeago.js/lib/lang/nb_NO.js'

export default class extends Controller {

  connect() {
    register('nb_NO', nb_NO)
    const nodes = document.querySelectorAll('.timeago');
    if (nodes.length >= 1) {
      render(nodes, 'nb_NO', { minINterval: 3 })
    }

  }
}
import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"


export default class extends Controller {
  static targets = ["media"]
  initialize() {
    console.log('Initializing controller')
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create("ControllerChannel", {
        connected() {
          console.log('You are connected to the Mainframe')
        },
        disconnected() {
          //console.log('You are no longer ready for registration')
        },
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
          //if (data.cableReady) CableReady.perform(data.operations, { emitMissingElementWarnings: false })
        }
      })
    }
    return this._subscription
  }
  toggleMedia(e) {
    let id = this.mediaTarget.dataset.id
    this.subscription().perform('push_content', { id: id })
  }
}
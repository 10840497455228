import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
import { render, register } from 'timeago.js';
import nb_NO from 'timeago.js/lib/lang/nb_NO.js'

export default class extends Controller {
  static targets = ["message"]
  initialize() {
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create("ChatChannel", {
        connected() {
          console.log('You are connected to the Mainframe')
        },
        disconnected() {
          //console.log('You are no longer ready for registration')
        },
        received(data) {
          //if (data.cableReady) CableReady.perform(data.operations)
          if (data.cableReady) CableReady.perform(data.operations, { emitMissingElementWarnings: false })
          _this.calculateTime()

        }
      })
    }
    return this._subscription
  }

  calculateTime() {
    register('nb_NO', nb_NO)
    const nodes = document.querySelectorAll('.timeago');
    if (nodes.length >= 1) {
      render(nodes, 'nb_NO', { minINterval: 3 })
    }
  }

  deleteMessage(e) {
    let id = e.srcElement.dataset.id
    this.subscription().perform('delete_message', { id: id })
  }
}
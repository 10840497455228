import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  scrollTo(e) {
      e.preventDefault()
    let element = e.srcElement
    let to = document.getElementById(element.dataset.to)
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: to.offsetTop -50
      });
    }
}
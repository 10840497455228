import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['body', 'count', 'countline', 'response', 'to']

  connect() {
    this.updateChars()

  }

  updateChars() {
    let chars = this.bodyTarget.value.length
    let chars_left = 160 - chars
    this.countTarget.innerHTML = chars_left
    if (chars_left < 0) {
      this.countlineTarget.classList.add('text-red-600')
    } else {
      this.countlineTarget.classList.remove('text-red-600')
    }
  }

  sendTest(e) {
    e.preventDefault()
    console.log('sending_test')
    let url = this.toTarget.dataset.url
    let test_number = this.toTarget.value
    let body = this.bodyTarget.value
    let data = { sms_message: { test_number: test_number, body: body } }
    fetch(url, {
      method: 'Post',
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    }).then(response => this.giveTestMessageFeedback(response))
  }
  giveTestMessageFeedback(response) {
    if (response.ok) {
      let html = '<span class="text-green-500">Testmelding ble sendt</span>'
      this.responseTarget.innerHTML = html
    } else {
      let html = '<span class="text-red-500">Kunne ikke sende testmelding sjekk at du har oppgitt riktig telefonnr og at du har skrevet en melding</span>'
      this.responseTarget.innerHTML = html
    }
  }
}
function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}
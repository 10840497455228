import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
import { render, register } from 'timeago.js';
import nb_NO from 'timeago.js/lib/lang/nb_NO.js'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["chatbox", 'form', 'submit', 'text']
  initialize() {
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create("ChatChannel", {
        connected() {
          _this.chatboxTarget.scrollTop = _this.chatboxTarget.scrollHeight;
        },
        disconnected() {
          //console.log('You are no longer ready for registration')
        },
        received(data) {
          //if (data.cableReady) CableReady.perform(data.operations)
          if (data.cableReady) CableReady.perform(data.operations, { emitMissingElementWarnings: false })
          _this.calculateTime()
          _this.chatboxTarget.scrollTop = _this.chatboxTarget.scrollHeight;
        }
      })
    }
    return this._subscription
  }

  submitForm(event) {
    event.preventDefault()
    Rails.fire(this.formTarget, "submit")
    this.formTarget.reset()
    console.log("submitted")
  }

  keyPress(e) {
    if (e.key === "Enter") {
      Rails.fire(this.formTarget, 'submit')
      //this.postMessage()
      e.preventDefault()
      this.formTarget.reset()
    }
  }

  emptyText() {
    this.textTarget.value = ''
  }

  calculateTime() {
    register('nb_NO', nb_NO)
    const nodes = document.querySelectorAll('.timeago');
    if (nodes.length >= 1) {
      render(nodes, 'nb_NO', { minINterval: 3 })
    }
  }

}